<script>
  import videojs from 'video.js';
  import { mapActions, mapMutations } from 'vuex';
  import CompleteButton from '@/components/courses/course-details/complete-button/CompleteButton.vue';
  import getThumbnail from '@/utils/getThumbnail.js';
  import 'video.js/dist/video-js.css';
  // Forest : vjs-theme-forest
  import '@videojs/themes/dist/forest/index.css';
  export default {
    data() {
      return {
        isToggleFavoriteLoading: false,
        thumbnailUrl: null,
        isLoadingThumbnail: false,
        player: null,
        maxReachedDurationIntervalRef: null,
        alreadySavedToHistory: false,
        currentControlIcon: null,
      };
    },
    props: {
      course: {
        type: Object,
        required: true,
      },
    },
    components: {
      CompleteButton,
    },
    async created() {
      try {
        this.thumbnailUrl = await getThumbnail({
          url: this.course.coverUrl,
          folder: 'CoursesCovers',
        });
      } catch {
        // fallback to the original image
        this.thumbnailUrl = this.course.coverUrl;
      }
    },
    mounted() {
      const self = this;
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.on('play', function () {
          self.setCourseCurrentlyPlayed(self.course.id);
          self.startTrackingMaxReachedDuration();
          self.saveToHistory();
        });

        this.player.on('pause', function () {
          self.setCourseCurrentlyPlayed(null);
          self.stopTrackingMaxReachedDuration();
        });

        this.player.on('ended', function () {
          self.setCourseCurrentlyPlayed(null);
          self.stopTrackingMaxReachedDuration();
        });
      });
    },
    beforeDestroy() {
      this.setCourseCurrentlyPlayed(null);
      if (this.player) {
        this.player.dispose();
        this.stopTrackingMaxReachedDuration();
      }
    },
    methods: {
      ...mapMutations(['setErrorSnackbarMessage']),
      ...mapMutations('CoursesModule', ['setCourseCurrentlyPlayed']),
      ...mapActions('CoursesModule', ['switchFavoriteState', 'addCourseToUserHistory']),
      ...mapActions('StatsModule', ['saveMaxReachedDuration']),
      async toggleFavouriteStatus() {
        this.isToggleFavoriteLoading = true;
        try {
          await this.switchFavoriteState(this.course.id);
          this.$emit('toggleFavouriteStatus');
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isToggleFavoriteLoading = false;
        }
      },
      async saveToHistory() {
        // exit if this is already done
        if (this.alreadySavedToHistory) {
          console.log('already saved To History ');
          return;
        }
        try {
          await this.addCourseToUserHistory(this.course.id);
          this.alreadySavedToHistory = true;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        }
      },
      startTrackingMaxReachedDuration() {
        // Start a timer to track the duration of the video that is being played
        const MAX_REACHED_DURATION_INTERVAL_DURATION = 60000;
        this.maxReachedDurationIntervalRef = setInterval(() => {
          this.saveMaxReachedDuration(this.currentTime);
        }, MAX_REACHED_DURATION_INTERVAL_DURATION); // update the course progress every minute
      },
      stopTrackingMaxReachedDuration() {
        // Stop the timer
        clearInterval(this.maxReachedDurationIntervalRef);
      },
    },
    computed: {
      currentTime() {
        if (this.player) {
          return this.player.currentTime();
        } else {
          return 0;
        }
      },
      heartIconDefault() {
        return require('@/assets/course/heart-icon-default.svg');
      },
      options() {
        return {
          autoplay: false,
          controls: true,
          poster: this.course.coverUrl,
          playbackRates: [0.5, 1, 1.5, 2],
          sources: [
            {
              src: this.course.videoUrl,
              type: 'video/mp4',
            },
          ],
        };
      },
    },
  };
</script>
